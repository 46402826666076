import type { ComponentProps, ReactNode } from 'react'
import { createContext, useCallback, useContext } from 'react'

import { omit } from 'remeda'

import { Dialog } from '../dialog/dialog'
import { DialogBackdrop } from '../dialog/dialog-backdrop'
import { DialogContainer } from '../dialog/dialog-container'

type Props = {
    children: ReactNode
    placement: 'left' | 'right' | 'bottom'
    disablePortal?: boolean
    disableFocusTrap?: boolean
    onOpen?: () => void
    onClose?: (event: CustomEvent<unknown>) => void
    /**
     * Callback fired before `onClose` is called whenever the user clicks on the backdrop or hits the ESC button.
     * Closing Drawer can be stopped by using calling `event.preventDefault()` on the event passed to the onDismiss callback
     */
    onDismiss?: (event: CustomEvent<unknown>) => Promise<void> | void
} & Pick<ComponentProps<typeof Dialog>, 'css'>

const InsideDrawerContext = createContext(false)

export const useInsideDrawer = () => useContext(InsideDrawerContext)

const generatePositionCss = (placement: Props['placement']) => {
    const basePositions = {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    }

    const reversedPositions = {
        left: 'right',
        right: 'left',
        bottom: 'top',
    } as const

    const omittedPosition = reversedPositions[placement]

    return omit(basePositions, [omittedPosition])
}

export const DrawerContainer = ({
    children,
    css,
    placement,
    disablePortal,
    onClose,
    onOpen,
    onDismiss,
    disableFocusTrap,
}: Props) => {
    const handleOnDismissDrawer = async (event: CustomEvent<unknown>) => {
        await onDismiss?.(event)

        if (!event.defaultPrevented) {
            onClose?.(event)
        }
    }

    const refOnOpen = useCallback(
        (node: HTMLElement | null) => {
            // ref callbacks are also called on unmount with node being null
            if (node) {
                onOpen?.()
            }
        },
        [onOpen],
    )

    return (
        <InsideDrawerContext.Provider value>
            <DialogContainer disablePortal={disablePortal}>
                <DialogBackdrop ref={refOnOpen}>
                    <Dialog
                        autoFocus
                        contain={!disableFocusTrap}
                        preventScroll
                        restoreFocus
                        borderRadius="none"
                        css={[generatePositionCss(placement), css]}
                        onClose={handleOnDismissDrawer}
                        isOutsideDismissable={false}
                    >
                        {children}
                    </Dialog>
                </DialogBackdrop>
            </DialogContainer>
        </InsideDrawerContext.Provider>
    )
}

DrawerContainer.displayName = 'DrawerContainer'
