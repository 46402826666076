import { last } from 'remeda'
import { z } from 'zod'

export const apiErrorSchema = z.object({
    detail: z.string().optional(),
    errors: z
        .array(
            z.object({
                id: z.string().uuid(),
                title: z.string(),
                detail: z.string().optional(),
                meta: z
                    .object({ loc: z.array(z.string()), msg: z.string(), type: z.string() })
                    .partial()
                    .optional(),
            }),
        )
        .optional(),
})

export const getFormErrors = (errors: z.infer<typeof apiErrorSchema>['errors']) => {
    if (!errors || errors.length === 0) {
        return undefined
    }

    return errors.map((error) => ({
        key: last(error.meta?.loc ?? []) ?? 'root.server',
        type: error.meta?.type,
        message: error.meta?.msg,
    }))
}

type FormError = {
    key: string
    type: string | undefined
    message: string | undefined
}

export class ApiError extends Error {
    readonly status: number

    readonly formErrors: Array<FormError>

    readonly originalError: unknown

    constructor({
        status,
        message,
        formErrors = [],
        originalError,
    }: {
        status: number
        message: string
        formErrors?: Array<FormError>
        originalError?: Error
    }) {
        const errorMessage = message.startsWith('<') ? 'Service unavailable' : message || 'Something went wrong'

        super(errorMessage)
        this.status = status
        this.originalError = originalError
        this.formErrors = formErrors
        this.name = 'ApiError'
        Object.setPrototypeOf(this, new.target.prototype)
    }
}
