import type { ComponentProps } from 'react'

import { Image } from '@adverity/design-system'

import type { StaticAsset } from './generated/assets'
import { assetsMap } from './generated/assets'

export type { StaticAsset } from './generated/assets'

type Props = {
    src: StaticAsset
    maxWidth?: number
} & Omit<ComponentProps<typeof Image>, 'src'>

export const staticImageUrl = (src: StaticAsset) => assetsMap[src]
export const StaticImage = ({ alt, src, ...props }: Props) => <Image {...props} alt={alt} src={staticImageUrl(src)} />
