import type { ReactNode } from 'react'
import { createContext, useContext, useMemo } from 'react'

import { isValid } from 'date-fns'

import { formatRelativeTime } from './date-format'
import {
    getCompactNumberFormat,
    getDecimalPoint,
    getNumberPrefix,
    getPrefixedNumber,
    getThousandsSeparator,
} from './number-format'

// 0 = Sunday
export type WeekStartsOn = 0 | 1 | 2 | 3 | 4 | 5 | 6
type DateFormat = string & { __brand: 'Valid Date Format' }

type BaseLocaleValue = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    locale: 'en-GB' | 'en-US' | 'de-AT' | 'de-DE' | 'sv-SE' | (string & {})
    weekStartsOn: WeekStartsOn
}

type FormatNumberOptions = Pick<
    Intl.NumberFormatOptions,
    'minimumFractionDigits' | 'maximumFractionDigits' | 'currency' | 'minimumIntegerDigits' | 'currencyDisplay'
> & {
    style?: 'percent' | 'currency' | 'decimal'
    compactNumbers?: boolean
    prefixPositiveNumbers?: boolean
}
export type LocaleValue = BaseLocaleValue & {
    formatDate: (date: Date, options?: Intl.DateTimeFormatOptions) => string
    formatNumber: (value: number, options?: FormatNumberOptions) => string
    formatRelativeTime: typeof formatRelativeTime
    numberThousandsSeparator: string
    numberDecimalPoint: string
}

export type LocaleContextValue = LocaleValue & {
    dateFormat: DateFormat
    dateSeparator: string
}

const baseDate = new Date(2020, 11, 24, 12, 0, 0)
const defaultDateFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
} satisfies Intl.DateTimeFormatOptions

const systemOverrides = {
    numberingSystem: 'latn',

    // some locales use different calendars by default, which might be confusing since we
    // already enforce the latin numbering system. Eg for 'ar-SA', which uses arabic numerals
    // by default, and the islamic calendar by default. I can imagine it to be confusing
    // having latin numerals, but still the islamic calendar.
    calendar: 'gregory',
} satisfies Intl.DateTimeFormatOptions

const createContextValue = ({ locale, weekStartsOn }: BaseLocaleValue): LocaleContextValue => {
    const parts = new Intl.DateTimeFormat(locale, {
        ...defaultDateFormatOptions,
        ...systemOverrides,
    }).formatToParts(baseDate)
    const dateFormat = parts
        .map((part) => {
            switch (part.type) {
                case 'day': {
                    return 'd'.repeat(part.value.length)
                }
                case 'month': {
                    return 'M'.repeat(part.value.length)
                }
                case 'year': {
                    return 'y'.repeat(part.value.length)
                }
                case 'literal': {
                    return part.value
                }
                default: {
                    return null
                }
            }
        })
        .filter(Boolean)
        .join('') as DateFormat

    return {
        locale,
        weekStartsOn,
        dateFormat,
        dateSeparator: parts.find((part) => part.type === 'literal')?.value ?? '',
        formatDate: (date, options) => {
            if (!isValid(date)) {
                return String(date)
            }

            return new Intl.DateTimeFormat(locale, {
                ...(options ?? defaultDateFormatOptions),
                ...systemOverrides,
            }).format(date)
        },
        formatNumber: (value, options = {}) => {
            const resilientOptions = {
                ...options,
                style: options.style === 'currency' && !options.currency ? 'decimal' : options.style,
            }

            const formattedNumber = new Intl.NumberFormat(locale, resilientOptions).format(value)

            return getPrefixedNumber(
                resilientOptions.compactNumbers
                    ? getCompactNumberFormat(
                          value,
                          (compactValue) => new Intl.NumberFormat(locale, resilientOptions).format(compactValue),
                          resilientOptions.style === 'percent',
                          resilientOptions.style === 'currency',
                      )
                    : formattedNumber,
                getNumberPrefix(value, !!resilientOptions.prefixPositiveNumbers),
            )
        },
        formatRelativeTime,
        numberThousandsSeparator: getThousandsSeparator(locale),
        numberDecimalPoint: getDecimalPoint(locale),
    }
}

export const enGB = 'en-GB'

const LocaleContext = createContext<LocaleContextValue>(createContextValue({ locale: enGB, weekStartsOn: 1 }))

export const useLocaleContext = () => useContext(LocaleContext)
// we're limiting the available "public" values only on TS level
export const useLocale = (): LocaleValue => useLocaleContext()

export const LocaleProvider = ({
    locale,
    weekStartsOn,
    children,
}: Partial<BaseLocaleValue> & { children: ReactNode }) => {
    const defaultLocale = useLocale()
    const value = useMemo(
        () =>
            createContextValue({
                locale: locale ?? defaultLocale.locale,
                weekStartsOn: weekStartsOn ?? defaultLocale.weekStartsOn,
            }),
        [defaultLocale.weekStartsOn, defaultLocale.locale, weekStartsOn, locale],
    )

    return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
}
