import type { ElementType, ReactElement, ReactNode } from 'react'
import { forwardRef } from 'react'

import type { CSSObject, SystemStyleObject } from '@styled-system/css'
import rawStyledSystemCss from '@styled-system/css'
import { css } from 'emotion'
import { fromPairs, omit, pick, sortBy, toPairs } from 'remeda'
import { compose } from 'styled-system'
import { useDeepCompareMemo } from 'use-deep-compare'

import { borders } from '../../system/styles/borders'
import type { BorderProps } from '../../system/styles/borders'
import type { ColorProps } from '../../system/styles/color'
import { color } from '../../system/styles/color'
import { flex } from '../../system/styles/flex'
import type { FlexProps } from '../../system/styles/flex'
import { layout } from '../../system/styles/layout'
import type { LayoutProps } from '../../system/styles/layout'
import { position } from '../../system/styles/position'
import type { PositionProps } from '../../system/styles/position'
import { sideBorders } from '../../system/styles/side-borders'
import type { SideBorderProps } from '../../system/styles/side-borders'
import { space } from '../../system/styles/space'
import type { SpaceProps } from '../../system/styles/space'
import type { Theme } from '../../theme/types'
import type { CSSProp, HTMLAttributes, Ref } from '../../types'
// eslint-disable-next-line import/no-named-as-default-member, import/no-named-as-default
import mergeCss from '../../utils/merge-css'
import { useTheme } from '../../utils/use-theme'

export type SystemProps = BorderProps &
    SideBorderProps &
    FlexProps &
    LayoutProps &
    PositionProps &
    SpaceProps &
    ColorProps

type RenderProps = (
    props: {
        ref: Ref<HTMLElement>
    } & Omit<Props, 'as' | 'children' | 'omitProps' | 'wrapElement'>,
) => JSX.Element

export type Props = SystemProps & {
    children?: ReactNode | RenderProps
    css?: CSSProp
    as?: ElementType | keyof JSX.IntrinsicElements
    omitProps?: Array<string>
    wrapElement?: (element: ReactNode) => ReactElement
} & HTMLAttributes

const styledSystemCss = rawStyledSystemCss as unknown as (
    input?: SystemStyleObject,
) => (props?: Theme | { theme: Theme }) => CSSObject

const systemProps = [
    color.propNames,
    flex.propNames,
    layout.propNames,
    position.propNames,
    space.propNames,
    borders.propNames,
    sideBorders.propNames,
]
    .flat()
    .filter((value): value is string => !!value)

const prepareBorders = (calculatedBorders: Record<string, unknown>): Record<string, unknown> =>
    fromPairs(
        sortBy(toPairs(calculatedBorders), ([propertyName]) =>
            // ensure border-color is always BELOW border-*
            propertyName === 'borderColor' ? 1 : -1,
        ),
    )

const composedSystemBorderFns = compose(borders, sideBorders)
const composedSystemOtherFns = compose(color, flex, layout, position, space)

const focusStyles = {
    outline: 'none',
    // On firefox there is a inner focus ring created by that
    // `::-moz-focus-inner`. Since we have our own focus-style we remove it.
    '::-moz-focus-inner': {
        border: 0,
    },
}

const defaultStyles: CSSObject = {
    '&:focus:not(:focus-visible)': focusStyles,
    '&:focus:not(.focus-visible)': focusStyles,
    boxSizing: 'border-box',
    verticalAlign: 'top',
    fontFamily: 'inherit',
    margin: 0,
    padding: 0,
    borderWidth: 0,
}

export const Box = forwardRef<HTMLElement, Props>(
    (
        { as: asProp = 'div', children, css: cssProp, omitProps = [], className: classNameProp, wrapElement, ...props },
        ref,
    ) => {
        const theme = useTheme()

        const cssClassName = useDeepCompareMemo(
            () =>
                css([
                    defaultStyles,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    prepareBorders(composedSystemBorderFns({ theme, ...props })),
                    composedSystemOtherFns({
                        theme,
                        ...props,
                    }),
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    styledSystemCss(mergeCss(cssProp))(theme),
                ]),
            [pick(props, systemProps as Array<keyof typeof props>), cssProp, theme],
        )

        const className = `${cssClassName}${classNameProp ? ` ${classNameProp}` : ''}`

        if (typeof children === 'function') {
            return children({ className, ref, ...props })
        }

        const Component = asProp

        const element = (
            <Component
                className={className}
                ref={ref}
                {...(typeof asProp === 'string'
                    ? omit(props, [...systemProps, ...omitProps] as Array<keyof typeof props>)
                    : props)}
            >
                {children}
            </Component>
        )

        return wrapElement ? wrapElement(element) : element
    },
)

Box.displayName = 'Box'
