import type { ComponentProps, ComponentType, ReactNode } from 'react'
import { Suspense, memo } from 'react'

import {
    DialogProvider,
    LocaleProvider,
    ModalDialogLoader,
    ThemeProvider,
    ToastProvider,
    defaultTheme,
    renderToString as dsRenderToString,
} from '@adverity/design-system'
import { ModalDialogErrorBoundary } from '@adverity/error/error-boundary'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRoot } from 'react-dom/client'

import { retryWhenServerError } from '../api'
import { DatatapLocaleProvider } from '../features/DatatapLocaleProvider'

type RenderProps = {
    component: ReactNode
    container: HTMLElement
    wrapperProps?: ComponentProps<typeof ThemeProvider>['wrapperProps']
    renderToastProvider?: boolean
}

type PropsComparator<TComponent extends ComponentType> = (
    prevProps: Readonly<ComponentProps<TComponent>>,
    nextProps: Readonly<ComponentProps<TComponent>>,
) => boolean

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: retryWhenServerError,
        },
    },
})

export const render = ({ component, container, wrapperProps, renderToastProvider = false }: RenderProps) => {
    createRoot(container).render(
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <ThemeProvider theme={defaultTheme} wrapperProps={wrapperProps}>
                <DatatapLocaleProvider weekStartsOn={1}>
                    {renderToastProvider && <ToastProvider />}
                    <DialogProvider
                        wrapper={(props) => (
                            <Suspense fallback={<ModalDialogLoader onClose={props.onClose} />}>
                                <ModalDialogErrorBoundary {...props} />
                            </Suspense>
                        )}
                    >
                        {component}
                    </DialogProvider>
                </DatatapLocaleProvider>
            </ThemeProvider>
        </QueryClientProvider>,
    )
}

export const renderToString = (tree: ReactNode) =>
    dsRenderToString(
        <ThemeProvider>
            <LocaleProvider locale={navigator.language}>
                <DialogProvider>{tree}</DialogProvider>
            </LocaleProvider>
        </ThemeProvider>,
    )

// The solution is based on the github comment by @pie6k
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087#issuecomment-699521381
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const componentMemo = <TComponent extends ComponentType<any>>(
    Component: TComponent,
    propsComparator?: PropsComparator<TComponent>,
) => memo(Component, propsComparator) as unknown as TComponent
