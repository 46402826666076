import type { ReactElement, ReactNode, RefObject } from 'react'
import { isValidElement, useCallback, useMemo, useState } from 'react'

import { DialogContextProvider, useInternalDialog } from './dialog-context'
import type { DialogContextProps, DialogHookProps, DrawerHookProps } from './dialog-context'
import { findLastIndex, remove } from '../../utils/array'
import { DialogContainer, DisablePortalContext } from '../dialog/dialog-container'
import { Drawer } from '../drawer/drawer'
import { AlertDialog } from '../modal-dialog/alert-dialog'
import { ConfirmDialog } from '../modal-dialog/confirm-dialog'
import { DeleteDialog } from '../modal-dialog/delete-dialog'
import { ModalDialog } from '../modal-dialog/modal-dialog'
import { TooltipInternalProvider } from '../tooltip-internal/tooltip-internal-provider'

type ComponentType = 'dialog' | 'drawer'

type HookProps<TBaseType, TComponentType extends ComponentType> = TBaseType & {
    close: () => void
    componentType: TComponentType
}

type RenderByHookPropsArguments =
    | ReactElement
    | HookProps<DialogHookProps, 'dialog'>
    | HookProps<DrawerHookProps, 'drawer'>

const isHookProps = (
    hookProps: RenderByHookPropsArguments,
): hookProps is HookProps<DrawerHookProps, 'drawer'> | HookProps<DialogHookProps, 'dialog'> =>
    !isValidElement(hookProps) && 'componentType' in hookProps

const renderByHookProps = (props: RenderByHookPropsArguments) => {
    if (isValidElement(props)) {
        return { element: props }
    }

    if (isHookProps(props)) {
        const { componentType, body, close, onClose, disablePortal } = props

        const handleOnClose = () => {
            close()
            onClose?.()
        }

        if (componentType === 'drawer') {
            return {
                element: (
                    <Drawer {...props} onClose={handleOnClose}>
                        {body}
                    </Drawer>
                ),
                disablePortal,
            }
        }

        return {
            element: (
                <ModalDialog {...props} onClose={handleOnClose}>
                    {body}
                </ModalDialog>
            ),
            disablePortal,
        }
    }

    return { element: null }
}

type DialogProviderProps = {
    children: ReactNode
    wrapper?: (args: { onClose: () => void; children: ReactNode }) => ReactNode
}

export const DialogProvider = ({ children, wrapper }: DialogProviderProps) => {
    const [dialogs, setDialogs] = useState<
        Array<{
            componentType: ComponentType
            element: ReactElement | null
            disablePortal?: boolean
            triggerRef?: RefObject<HTMLElement | null>
        }>
    >([])

    const close = useCallback(
        (componentType: ComponentType) =>
            setDialogs((state) => {
                const foundLastIndex = findLastIndex(state, (element) => element.componentType === componentType)

                if (foundLastIndex !== -1) {
                    return remove(state, foundLastIndex)
                }

                return state
            }),
        [setDialogs],
    )

    const closeDialog = useCallback(() => close('dialog'), [close])
    const closeDrawer = useCallback(() => close('drawer'), [close])

    const closeMapping = {
        dialog: closeDialog,
        drawer: closeDrawer,
    } as const

    const open: DialogContextProps['open'] = useCallback(
        (dialog) => {
            setDialogs((state) => [
                ...state,
                {
                    componentType: 'dialog',
                    ...renderByHookProps({ ...dialog, close: closeDialog, componentType: 'dialog' }),
                },
            ])
        },
        [setDialogs, closeDialog],
    )

    const confirm: DialogContextProps['confirm'] = useCallback(
        ({ onClose, onConfirm, ...restProps }) =>
            new Promise((resolve) => {
                open(
                    <ConfirmDialog
                        onClose={() => {
                            closeDialog()

                            onClose?.()
                            resolve(false)
                        }}
                        onConfirm={() => {
                            closeDialog()

                            onConfirm?.()
                            resolve(true)
                        }}
                        {...restProps}
                    />,
                )
            }),
        [open, closeDialog],
    )

    const alert: DialogContextProps['alert'] = useCallback(
        ({ onClose, ...restOfProps }) =>
            open(
                <AlertDialog
                    onClose={() => {
                        closeDialog()

                        onClose?.()
                    }}
                    {...restOfProps}
                />,
            ),
        [open, closeDialog],
    )

    const confirmDelete: DialogContextProps['confirmDelete'] = useCallback(
        ({ onClose, onConfirm, ...restProps }) =>
            new Promise((resolve) => {
                open(
                    <DeleteDialog
                        onClose={() => {
                            closeDialog()

                            onClose?.()
                            resolve(false)
                        }}
                        onConfirm={() => {
                            closeDialog()

                            onConfirm?.()
                            resolve(true)
                        }}
                        {...restProps}
                    />,
                )
            }),
        [open, closeDialog],
    )

    const drawer: DialogContextProps['drawer'] = useCallback(
        (drawerProps) => {
            setDialogs((state) => [
                ...state,
                {
                    componentType: 'drawer',
                    ...renderByHookProps({ ...drawerProps, close: closeDrawer, componentType: 'drawer' }),
                },
            ])
        },
        [setDialogs, closeDrawer],
    )

    const providerValue = useMemo(
        () => ({
            open,
            closeDialog,
            closeDrawer,
            confirm,
            alert,
            confirmDelete,
            drawer,
        }),
        [open, closeDialog, closeDrawer, confirm, alert, confirmDelete, drawer],
    )

    return (
        <DialogContextProvider value={providerValue}>
            <TooltipInternalProvider>
                <DisablePortalContext.Provider value={false}>{children}</DisablePortalContext.Provider>

                {dialogs.map((dialog, index) => {
                    const element = wrapper
                        ? wrapper({ onClose: closeMapping[dialog.componentType], children: dialog.element })
                        : dialog.element

                    return (
                        // `index` is stable
                        // eslint-disable-next-line react/no-array-index-key
                        <DialogContainer disablePortal={dialog.disablePortal} key={index}>
                            <DisablePortalContext.Provider value>{element}</DisablePortalContext.Provider>
                        </DialogContainer>
                    )
                })}
            </TooltipInternalProvider>
        </DialogContextProvider>
    )
}

export const useDialog = () => {
    const { open, closeDialog: close, alert, confirm, confirmDelete } = useInternalDialog()

    return { open, close, alert, confirm, confirmDelete }
}
