import { useCallback, useState } from 'react'

import { colord } from 'colord'

import 'focus-visible'
import { isDark, setAlpha } from './color'
import { useTheme } from './use-theme'
import { defaultTheme } from '../theme/themes/default.theme'
import type { CSSProp, SchemaMapping, Variant } from '../types'

/**
 * Hooks to generate consistent focusStyle properties for focus rings
 *
 * The styles are separated from the focus selector because not all components
 * use the same css for focusStyle. This is the most common one.
 */

type FocusStyleColor = 'brand' | 'inverted' | Variant | SchemaMapping | 'neutral'

const resolveBorderColor = (color: FocusStyleColor) => {
    switch (color) {
        case 'brand': {
            return 'backgroundInverted' as const
        }
        case 'inverted': {
            return 'borderInverted' as const
        }
        default: {
            return `${color}Active` as const
        }
    }
}

const resolveBoxShadowColor = (color: FocusStyleColor) => {
    if (color === 'inverted') {
        return 'borderInverted'
    }

    if (color === 'neutral') {
        return 'brand'
    }

    return color
}

export const useFocusStyle = (color: FocusStyleColor = 'brand', minimal = false, alpha = 0.2) => {
    const theme = useTheme()

    return {
        outline: 'none',
        ...(!minimal && {
            borderColor: resolveBorderColor(color),
        }),
        boxShadow: `0 0 0 4px ${setAlpha(theme.colors[resolveBoxShadowColor(color)], alpha).toHex()}`,
    }
}

export const useFocusRingColorForNode = (): [
    focusRingColorProps: { focusRingColor: FocusStyleColor; minimal: false; alpha: 0.25 },
    setNodeForCurrentColor: (node: Element | null) => void,
    currentColor: string,
] => {
    const [currentColor, setCurrentColor] = useState('')

    const setNodeForCurrentColor = useCallback((node: Element | null) => {
        if (node !== null) {
            setCurrentColor(getComputedStyle(node).color)
        }
    }, [])
    // Text muted is special case in this situation because it's just color that it's just between dark and light palette.
    // In this situation we should force to use brand color (light palette) as isDark return true in this situation.
    const isTextMuted = colord(currentColor).toHex().toUpperCase() === defaultTheme.colors.textMuted

    const focusRingColor = isTextMuted || isDark(currentColor) ? 'brand' : 'inverted'

    return [{ focusRingColor, minimal: false, alpha: 0.25 }, setNodeForCurrentColor, currentColor]
}

export const getFocusRing = (styles: CSSProp) => ({
    '&.focus-visible': styles,
    '&:focus-visible': styles,
    '&[data-focus-visible-added]': styles,
})
