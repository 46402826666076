import type { ComponentPropsWithRef, ReactNode } from 'react'
import { forwardRef } from 'react'

import { headerFooterHeight } from './dialog'
import { useSlotProps } from '../../utils/components/slots'
import { Flex } from '../flex/flex'
import { IconButtonMinimal } from '../icon-button/icon-button-minimal'
import { IconCloseLarge } from '../icons/generated/close-large'

type Props = {
    children: ReactNode
    onClose: () => void
} & ComponentPropsWithRef<typeof Flex>

export const DialogHeader = forwardRef<HTMLElement, Props>(({ onClose, children, ...props }, ref) => {
    const { id, 'aria-label': ariaLabel, ...slotProps } = useSlotProps(props, 'header')

    return (
        <Flex
            ref={ref}
            minHeight={headerFooterHeight}
            paddingX="medium"
            alignItems="center"
            justifyContent="space-between"
            css={{ whiteSpace: 'nowrap' }}
            {...slotProps}
        >
            <Flex id={id} aria-label={ariaLabel} width="100%">
                {children}
            </Flex>
            <IconButtonMinimal
                size="small"
                label="Close"
                rounded
                icon={<IconCloseLarge />}
                onClick={onClose}
                tabIndex={-1}
            />
        </Flex>
    )
})

DialogHeader.displayName = 'DialogHeader'
