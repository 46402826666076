import type { ReactNode } from 'react'
import { createContext, forwardRef, useContext, useMemo } from 'react'

import type * as CSS from 'csstype'

import type { ColorProps } from '../../system/styles/color'
import type { PositionProps } from '../../system/styles/position'
import type { SpaceProps } from '../../system/styles/space'
import type { TypographyProps } from '../../system/styles/typography'
import { isColorToken } from '../../theme/tokens/colors'
import type { FontSizesAccessPaths, LineHeightsAccessPaths } from '../../theme/tokens/generated/token-access-paths'
import { getFocusRing, useFocusStyle } from '../../utils/focus'
import { useIsTruncating } from '../../utils/use-is-truncating'
import { useTheme } from '../../utils/use-theme'
import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'
import { ConditionalWrapWithTooltip } from '../tooltip-internal/conditional-wrap-with-tooltip'

type TextStyle = 'title' | 'body' | 'description' | 'details' | 'inherit'
type ColorProp = ColorProps['color']

export type TextProps = Pick<
    BoxProps,
    | 'as'
    | 'display'
    | 'css'
    | 'maxWidth'
    | 'minWidth'
    | 'width'
    | 'id'
    | 'role'
    | 'dangerouslySetInnerHTML'
    | 'tabIndex'
    | 'type'
> &
    SpaceProps &
    ColorProps &
    PositionProps &
    Omit<TypographyProps, 'fontSize'> & {
        children?: ReactNode
        dateTime?: HTMLTimeElement['dateTime']
        fontVariant?: CSS.Property.FontVariant
        noWrap?: boolean
        wordBreak?: CSS.Property.WordBreak
        textDecoration?: CSS.Property.TextDecoration
        textDecorationColor?: ColorProp
        textDecorationLine?: CSS.Property.TextDecorationLine
        textDecorationStyle?: CSS.Property.TextDecorationStyle
        textDecorationThickness?: CSS.Property.TextDecorationThickness
        textStyle?: TextStyle
        textTransform?: CSS.Property.TextTransform
        truncateLines?: number
        verticalAlign?: CSS.Property.VerticalAlign
    }

const textStyles: {
    [key in TextStyle]: {
        fontSize: FontSizesAccessPaths | 'inherit'
        lineHeight: LineHeightsAccessPaths | 'inherit'
    }
} = {
    title: {
        fontSize: 'medium',
        lineHeight: 'medium',
    },
    body: {
        fontSize: 'small',
        lineHeight: 'small',
    },
    description: {
        fontSize: 'x-small',
        lineHeight: 'x-small',
    },
    details: {
        fontSize: 'xx-small',
        lineHeight: 'xx-small',
    },
    inherit: {
        fontSize: 'inherit',
        lineHeight: 'inherit',
    },
}

const TextContext = createContext({
    isNestedText: false,
})

export const Text = forwardRef<HTMLElement, TextProps>(
    (
        {
            as = 'span',
            children,
            color,
            css,
            dangerouslySetInnerHTML,
            display,
            fontFamily,
            fontStyle,
            fontWeight,
            fontVariant,
            letterSpacing,
            maxWidth,
            minWidth,
            noWrap,
            textAlign,
            textDecoration,
            textDecorationColor,
            textDecorationLine,
            textDecorationStyle,
            textDecorationThickness,
            textStyle,
            textTransform,
            truncateLines,
            verticalAlign,
            width,
            wordBreak,
            ...props
        },
        ref,
    ) => {
        const theme = useTheme()
        const { isNestedText } = useContext(TextContext)
        const actualTextStyle = textStyle ?? (isNestedText ? 'inherit' : 'body')
        const actualVerticalAlign = verticalAlign ?? (isNestedText ? 'inherit' : 'baseline')
        const actualFontWeight = fontWeight ?? (isNestedText ? 'inherit' : 'normal')
        const actualColor = color ?? (textStyle === 'description' ? 'textSecondary' : color)
        const actualWordBreak = wordBreak ?? (truncateLines === 1 ? 'break-all' : undefined)
        const textContextValue = useMemo(() => ({ isNestedText: true }), [])

        const {
            truncateContentRef,
            isTruncating,
            textContent,
            nodeForCurrentColor: { focusRingColor, minimal, alpha },
        } = useIsTruncating({ truncateLines, children })

        const truncateSettings = {
            display: '-webkit-box',
            WebkitLineClamp: `${truncateLines}`,
            WebkitBoxOrient: 'vertical',
            ...getFocusRing(useFocusStyle(focusRingColor, minimal, alpha)),
        } as const

        const content = truncateLines ? (
            <Box
                overflow={isTruncating ? 'hidden' : 'visible'}
                width={width}
                maxWidth={maxWidth}
                minWidth={minWidth}
                css={truncateSettings}
                verticalAlign={actualVerticalAlign}
            >
                <Box as="span" ref={truncateContentRef} verticalAlign="inherit">
                    {children}
                </Box>
            </Box>
        ) : (
            children
        )

        return (
            <TextContext.Provider value={textContextValue}>
                <ConditionalWrapWithTooltip tooltip={isTruncating ? textContent : undefined}>
                    <Box
                        display={isTruncating ? 'inline-block' : display}
                        width={width}
                        maxWidth={maxWidth}
                        minWidth={minWidth}
                        as={as}
                        ref={ref}
                        color={actualColor}
                        verticalAlign={actualVerticalAlign}
                        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                        tabIndex={isTruncating ? 0 : -1}
                        {...props}
                        css={[
                            {
                                ...textStyles[actualTextStyle],
                                fontFamily,
                                fontStyle,
                                fontWeight: actualFontWeight,
                                fontVariant,
                                letterSpacing,
                                textAlign,
                                textDecoration,
                                textDecorationColor: isColorToken(textDecorationColor)
                                    ? theme.colors[textDecorationColor]
                                    : textDecorationColor,
                                textDecorationLine,
                                textDecorationStyle,
                                textDecorationThickness,
                                textTransform,
                                whiteSpace: noWrap ? 'nowrap' : undefined,
                                wordBreak: actualWordBreak,
                            },
                            css,
                        ]}
                    >
                        {dangerouslySetInnerHTML ? undefined : content}
                    </Box>
                </ConditionalWrapWithTooltip>
            </TextContext.Provider>
        )
    },
)

Text.displayName = 'Text'
