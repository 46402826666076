import type { FocusEventHandler, MouseEventHandler } from 'react'
import { forwardRef } from 'react'

import { buttonLabel } from './constants/button-label'
import { Box } from '../box/box'
import { ButtonMinimal } from '../button/button-minimal'
import { IconButtonMinimalCore } from '../icon-button/icon-button-minimal-core'
import { IconInfo } from '../icons/generated/info'
import type { PopoverTriggerRenderProps } from '../popover/types'

export type Props = PopoverTriggerRenderProps & {
    isTooltipVisible?: boolean
    onMouseEnter?: MouseEventHandler
    onMouseLeave?: MouseEventHandler
    ariaControls?: string
    ariaExpanded?: boolean
    onFocus: FocusEventHandler
    onBlur: FocusEventHandler
    label?: string
}

const stylesTriggerProxy = {
    width: 'fit-content',
    height: 'fit-content',
} as const

export const InfoTipTrigger = forwardRef<HTMLButtonElement, Props>(
    (
        {
            ariaControls,
            ariaExpanded,
            disabled,
            onClick,
            onMouseEnter,
            onMouseLeave,
            onFocus,
            onBlur,
            isTooltipVisible,
            label,
        },
        ref,
    ) => (
        <Box
            css={stylesTriggerProxy}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onFocus={onFocus}
            onBlur={onBlur}
        >
            {label ? (
                <ButtonMinimal
                    aria-controls={ariaControls}
                    aria-expanded={ariaExpanded}
                    ref={ref}
                    onClick={onClick}
                    prefix={<IconInfo />}
                >
                    {label}
                </ButtonMinimal>
            ) : (
                <IconButtonMinimalCore
                    ref={ref}
                    size="small"
                    aria-controls={ariaControls}
                    aria-expanded={ariaExpanded}
                    aria-label={buttonLabel}
                    disabled={disabled}
                    isTooltipVisible={isTooltipVisible}
                    icon={<IconInfo />}
                    onClick={onClick}
                    label={buttonLabel}
                />
            )}
        </Box>
    ),
)

InfoTipTrigger.displayName = 'InfoTipTrigger'
