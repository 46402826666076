const compactNumbersOptions = [
    { suffix: 'B', limit: 1000000000 },
    { suffix: 'M', limit: 1000000 },
    { suffix: 'K', limit: 1000 },
]

const symbolsAtEnd = /^(.*?)(\s*)(\D*)$/

type Formatter = (value: number) => string

export const getCompactNumberFormat = (
    value: number,
    intlNumberFormatter: Formatter,
    isPercent = false,
    isCurrency = false,
    // eslint-disable-next-line max-params
): string => {
    const absoluteValue = Math.abs(value)
    const option = compactNumbersOptions.find(({ limit }) => (isPercent ? absoluteValue * 100 : absoluteValue) >= limit)

    if (option) {
        const divided = value / option.limit
        const formattedValue = intlNumberFormatter(divided)

        if (isCurrency || isPercent) {
            const [, number, spaces, symbol] = formattedValue.match(symbolsAtEnd) ?? []

            return [number, option.suffix, spaces, symbol].filter((part) => !!part).join('')
        }

        return `${formattedValue}${option.suffix}`
    }

    return intlNumberFormatter(value)
}

export const getNumberPrefix = (value: number, prefixPositiveNumbers: boolean) => {
    if (prefixPositiveNumbers) {
        if (value === 0) {
            return '±'
        }

        if (value > 0) {
            return '+'
        }
    }

    return ''
}

export const getPrefixedNumber = (formattedNumber: string, prefix: string) => `${prefix}${formattedNumber}`

export const getDecimalPoint = (locale: string) => {
    const numberFormat = new Intl.NumberFormat(locale)
    const decimalPoint = numberFormat.formatToParts(1.1)[1]

    return decimalPoint && decimalPoint.type === 'decimal' ? decimalPoint.value : '.'
}

export const getThousandsSeparator = (locale: string) => {
    const numberFormat = new Intl.NumberFormat(locale)
    const thousandsSeparator = numberFormat.formatToParts(1000)[1]

    return thousandsSeparator && thousandsSeparator.type === 'group' ? thousandsSeparator.value : ''
}
